import Image from 'next/image';
import Link from 'next/link';
import styles from './ErrorPage.module.scss';

interface ErrorPageProps {
  image: any;
  header: string;
  content: string;
}

export default function ErrorPage({ image, header, content }: ErrorPageProps) {
  return (
    <div className={`${styles.errorPage} content`}>
      <div className={styles.errorImage}>
        <Image src={image} alt="Error 404 Cat Image" placeholder="blur" />
      </div>
      <h1>{header}</h1>
      <p>{content}</p>

      <Link href="/">Return Home</Link>
    </div>
  )
};
